body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'Open Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  p, h1, h2, h3 {
    margin: 0;
  }
  
  a {
    margin: 0;
    text-decoration: none;
    color: unset;
  }
}

