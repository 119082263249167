.typography {
  font-family: Open Sans;
  font-weight: 300;
}

.typography-text--primary {
  font-size: 20px;
}

.typography-heading--primary {
  font-size: 55px;
  font-weight: 400;
  
  @media screen and (max-width: 780px) {
    font-size: 28px;
  } 
}

.typography-heading--text {
  font-size: 32px;
  font-weight: 400;
}

.typography-heading--secondary {
  font-size: 34px;
  font-weight: 400;
  
  @media screen and (max-width: 780px) {
    font-size: 32px;
  } 
}

.typography-heading--tretiary {
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 20px;
  border-bottom: 1px solid #D7DEDD;

  @media screen and (max-width: 780px) {
    font-size: 22px;
  } 
}

.typography-text--footer {
  font-size: 11px;
  font-weight: 400;

  & span {
    font-weight: 700;
  }
}

.typography-color--primary {
  color: #777777;
}

.typography-color--secondary {
  color: #555555;
}

.typography-color--heading {
  color: #87919F;
}

.typography-color--white {
  color: #FFFFFF;
}

.typography-color--black {
  color: #212529;
}

.typography-color--darker {
  color: #333333;
  font-size: 20px;

  @media screen and (max-width: 780px) {
    font-size: 18px;
  } 
}

.about-section--container {
  padding: 84px 52px;
  padding-bottom: 152px;  

  @media screen and (max-width: 780px) {
    padding: 64px 18px;
    padding-bottom: 64px;

  } 

  .about-section {
    display: flex;
    gap: 26px;
    max-width: 992px;
    text-align: center;
  }
}